export function faqList() {

	var $faqItems = $('.faqItem');
	var $allFaqBlocks = $('.faqItem__block');

	$faqItems.each(function(ix, ele){
		var $this = $(this),
			$link = $this.find(".faqItem__link"),
			$block = $this.find(".faqItem__block");

		$link.click(function(){ 
			if ($block.is(":visible")) {
				$block.slideUp();
			} else {
				$allFaqBlocks.slideUp();
				$block.slideDown();
			}
		});			
	});
}