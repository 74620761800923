export function imgCarousel() {
	var CAROUSEL_INTERVAL = 3000;
		
	$('.imgCarousel').each(function() {
		var currentImg = 0;
		var ticker;
		
		var $carousel = $(this);
		var	$images = $carousel.find('.imgCarousel__imgListImg');
		var $pips = $carousel.find('.imgCarousel__pip');
	
		var showImg = function(ix){
			currentImg = ((ix >= $images.length) || (ix < 0)) ? 0 : ix;
			clearTimeout(ticker);
			
			$images.addClass('is-hidden');
			$($images[currentImg]).removeClass('is-hidden');
			
			$pips.removeClass('is-active');
			$($pips[currentImg]).addClass('is-active');	
			
			ticker = setTimeout(increment, CAROUSEL_INTERVAL);	
		}

		var increment = function() {
			showImg(currentImg + 1);
		};

		$pips.each(function(ix){
			$(this).click(function(){ 
				showImg(ix) 
			});
		});

		ticker = setTimeout(increment, CAROUSEL_INTERVAL);	
	});	
}