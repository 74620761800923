export function mapEmbed() {

	$('.mapEmbed').each(function() {
		var $map = $(this);
		var latlng = new google.maps.LatLng(parseFloat($map.data("latitude")), parseFloat($map.data("longitude")));
		var title = $map.data("title");

		console.log(latlng);

		var initialize = function () {
			var mapOptions = {center: latlng, zoom: 14};					
			var map = new google.maps.Map($map[0], mapOptions);
			var marker = new google.maps.Marker({position: latlng, map: map, title: title});
		}

		$(window).load(initialize);
	});
}