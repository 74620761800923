export function secondaryHeader() { 
	var headerClone;

	var waypoints = $('.secondaryHeader').waypoint({
			handler: function(direction) {
			if (direction =='down') {
				headerClone =$(this).clone();
				headerClone.addClass('secondaryHeader--fixed').attr('role', 'presentation');
				$(this).after(headerClone);
			} else {
				headerClone.remove();
	 	 	}
	 	}	
	});
}