import {secondaryHeader} from './components/secondaryHeader.js';
import {imgCarousel} from './components/imgCarousel.js';
import {faqList} from './components/faqList.js';
import {mapEmbed} from './components/location.js';

$(function() {
	secondaryHeader();
	imgCarousel();
	faqList();
	mapEmbed();
});